import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { homeGAFunctions } from "../GA/HomeGA";
import constantsValues, { defautlChainId } from "../constants/constantsValues";
import { appImages } from "../constants/images";
import { AuthContext } from "../context/AuthContext";
import { walletConnect } from "../helper/WalletConnect";
import "./header.css";

export default function Header() {
  const navRoutes = [
    {
      name: "Home",
      route: "/",
      event: () => {
        homeGAFunctions.pressHomeEvent();
      },
    },
    {
      name: "Your Contracts",
      route: "/your-contracts",
      event: () => {
        homeGAFunctions.deployContractEvent();
        setToggle(0);
      },
    },
  ];

  const location = useLocation();

  const [toggle, setToggle] = useState(0);
  const [testnet, setTestnet] = useState(true);
  const [showDropdown, setShowDropdown] = useState(true);

  const clicktoggle = () => {
    setToggle(!toggle);
  };
  const { address, chainToConnect, setChainToConnect, chainNotSupport } = useContext(AuthContext);

  return (
    <header className="header">
      <div className="container">
        <Link to="/">
          <span className="image-box">
            <img src={appImages.lyncLogo} alt="collection" />
          </span>
        </Link>
        <div className="header-right">
          <nav className={toggle ? "navigations show" : "navigations"}>
            <ul className="navigation-list">
              {navRoutes.map((routes, id) => (
                <li
                  key={id}
                  className={`navigation-list-items ${location.pathname === routes.route ? "active" : ""}`}
                  onClick={routes.event}
                >
                  <Link to={routes.route}>{routes.name}</Link>
                </li>
              ))}
              <li
                className="navigation-list-items"
                onClick={() => {
                  homeGAFunctions.deployContractEvent();
                  setToggle(0);
                }}
              >
                <a
                  href="https://lync.gitbook.io/lync/introduction/introducing-lync"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tutorial
                </a>
              </li>
            </ul>
          </nav>
          {!address ? (
            <button
              onClick={() => {
                homeGAFunctions.connectWalletEvent();
                walletConnect(defautlChainId);
              }}
              className={toggle ? "connect-toggle" : "cannect-wallet-btn nav-btn"}
            >
              Connect Wallet
            </button>
          ) : (
            <div class="sec-center">
              <input class="dropdown checkbox-class" type="checkbox" id="dropdown" name="dropdown" />
              <label class="for-dropdown" for="dropdown" onClick={() => setShowDropdown(true)}>
                <span className="chain-connected">
                  {constantsValues[chainToConnect]?.logo ? (
                    <img src={constantsValues[chainToConnect].logo} alt="logo" />
                  ) : null}

                  {constantsValues[chainToConnect]?.ChainName}
                </span>
                {chainNotSupport === true ? "Unsupported Network" : null}
                <i class="arrowdown"></i>
              </label>
              <div class={showDropdown ? "section-dropdown" : "hide-drop section-dropdown"}>
                <p className="select-network">Select a Network</p>
                <div className="main-test">
                  <button className={!testnet ? "active mainnet" : "mainnet"} onClick={() => setTestnet(false)}>
                    Mainnet
                  </button>
                  <button className={testnet ? "active testnet" : "testnet"} onClick={() => setTestnet(true)}>
                    Testnet
                  </button>
                </div>
                <div className="option-container">
                  {testnet
                    ? Object.keys(constantsValues).map((keyName, i) => {
                        return (
                          <>
                            {constantsValues[keyName].testnet === "true" ? (
                              <a
                                className="drop-btn"
                                key={i}
                                onClick={() => {
                                  setChainToConnect(keyName);
                                }}
                              >
                                <span className="network-logo">
                                  {constantsValues[keyName].logo ? (
                                    <img src={constantsValues[keyName].logo} alt="logo" />
                                  ) : null}

                                  {constantsValues[keyName].ChainName}
                                </span>

                                {chainToConnect === keyName ? <i className="dot"></i> : null}
                              </a>
                            ) : null}
                          </>
                        );
                      })
                    : Object.keys(constantsValues).map((keyName, i) => {
                        return (
                          <>
                            {constantsValues[keyName].testnet === "false" ? (
                              <a
                                className="drop-btn"
                                key={i}
                                onClick={() => {
                                  setChainToConnect(keyName);
                                }}
                              >
                                <span className="network-logo">
                                  {constantsValues[keyName].logo ? (
                                    <img src={constantsValues[keyName].logo} alt="logo" />
                                  ) : null}

                                  {constantsValues[keyName].ChainName}
                                </span>

                                {chainToConnect === keyName ? <i className="dot"></i> : null}
                              </a>
                            ) : null}
                          </>
                        );
                      })}
                </div>
              </div>
            </div>
          )}
        </div>
        <span className="toggle" onClick={() => clicktoggle()}>
          <img src={appImages.navigationImg} alt="collection" />
        </span>
      </div>
    </header>
  );
}
