import { createAsGAFunctions } from "../../GA/CreateAsGA";

export function CreateOptions({ createOptionID, setCreateOptionID }) {
  return (
    <div className="create-options">
      <p className="options-heading">Create As</p>
      <ul className="options-list">
        <li
          className={`options-list-items ${
            createOptionID === 0 ? "active" : ""
          }`}
          onClick={() => {
            createAsGAFunctions.oneEvent();
            setCreateOptionID(0);
          }}
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4.75 4.75H14.25V14.25H4.75V4.75Z" fill="#333333" />
          </svg>
          1/1 NFT
        </li>
        <li
          className={`options-list-items ${
            createOptionID === 1 ? "active" : ""
          }`}
          onClick={() => {
            createAsGAFunctions.multipleEditionEvent();
            setCreateOptionID(1);
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1629_786)">
              <path
                d="M0 13C0 13.3978 0.158035 13.7794 0.43934 14.0607C0.720644 14.342 1.10218 14.5 1.5 14.5H14.5C14.8978 14.5 15.2794 14.342 15.5607 14.0607C15.842 13.7794 16 13.3978 16 13V6C16 5.60218 15.842 5.22064 15.5607 4.93934C15.2794 4.65804 14.8978 4.5 14.5 4.5H1.5C1.10218 4.5 0.720644 4.65804 0.43934 4.93934C0.158035 5.22064 0 5.60218 0 6L0 13ZM2 3C2 3.13261 2.05268 3.25979 2.14645 3.35355C2.24021 3.44732 2.36739 3.5 2.5 3.5H13.5C13.6326 3.5 13.7598 3.44732 13.8536 3.35355C13.9473 3.25979 14 3.13261 14 3C14 2.86739 13.9473 2.74021 13.8536 2.64645C13.7598 2.55268 13.6326 2.5 13.5 2.5H2.5C2.36739 2.5 2.24021 2.55268 2.14645 2.64645C2.05268 2.74021 2 2.86739 2 3ZM4 1C4 1.13261 4.05268 1.25979 4.14645 1.35355C4.24021 1.44732 4.36739 1.5 4.5 1.5H11.5C11.6326 1.5 11.7598 1.44732 11.8536 1.35355C11.9473 1.25979 12 1.13261 12 1C12 0.867392 11.9473 0.740215 11.8536 0.646447C11.7598 0.552678 11.6326 0.5 11.5 0.5H4.5C4.36739 0.5 4.24021 0.552678 4.14645 0.646447C4.05268 0.740215 4 0.867392 4 1Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_1629_786">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Multiple Edition
        </li>
        <li
          className={`options-list-items ${
            createOptionID === 2 ? "active" : ""
          }`}
          onClick={() => {
            createAsGAFunctions.collectionEvent();
            setCreateOptionID(2);
          }}
        >
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1629_788)">
              <path
                d="M14.1667 2.125C14.5425 2.125 14.9028 2.27426 15.1685 2.53993C15.4342 2.80561 15.5834 3.16594 15.5834 3.54167V13.4583C15.5834 13.8341 15.4342 14.1944 15.1685 14.4601C14.9028 14.7257 14.5425 14.875 14.1667 14.875H2.83341C2.45769 14.875 2.09736 14.7257 1.83168 14.4601C1.566 14.1944 1.41675 13.8341 1.41675 13.4583V3.54167C1.41675 3.16594 1.566 2.80561 1.83168 2.53993C2.09736 2.27426 2.45769 2.125 2.83341 2.125H14.1667ZM14.1667 3.54167H2.83341V10.6958L6.37154 7.15842C6.45376 7.07617 6.55138 7.01093 6.65882 6.96642C6.76626 6.92191 6.88141 6.899 6.99771 6.899C7.114 6.899 7.22916 6.92191 7.3366 6.96642C7.44403 7.01093 7.54165 7.07617 7.62387 7.15842L10.5032 10.0385L11.3802 9.16158C11.4624 9.07934 11.56 9.0141 11.6674 8.96959C11.7749 8.92508 11.89 8.90217 12.0063 8.90217C12.1226 8.90217 12.2378 8.92508 12.3452 8.96959C12.4527 9.0141 12.5503 9.07934 12.6325 9.16158L14.1667 10.6965V3.54167ZM10.9792 4.95833C11.261 4.95833 11.5313 5.07027 11.7305 5.26953C11.9298 5.46879 12.0417 5.73904 12.0417 6.02083C12.0417 6.30263 11.9298 6.57288 11.7305 6.77213C11.5313 6.97139 11.261 7.08333 10.9792 7.08333C10.6975 7.08333 10.4272 6.97139 10.2279 6.77213C10.0287 6.57288 9.91675 6.30263 9.91675 6.02083C9.91675 5.73904 10.0287 5.46879 10.2279 5.26953C10.4272 5.07027 10.6975 4.95833 10.9792 4.95833Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_1629_788">
                <rect width="17" height="17" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Collection
        </li>
        <li
          className={`options-list-items ${
            createOptionID === 3 ? "active" : ""
          }`}
          onClick={() => {
            createAsGAFunctions.launchGameEvent();
            setCreateOptionID(3);
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99992 1.33333C8.98808 1.33233 9.96406 1.55145 10.857 1.97477C11.7499 2.3981 12.5372 3.015 13.1619 3.78066L8.94258 8L13.1619 12.2193C12.5372 12.985 11.7499 13.6019 10.857 14.0252C9.96406 14.4486 8.98808 14.6677 7.99992 14.6667C4.31792 14.6667 1.33325 11.682 1.33325 8C1.33325 4.318 4.31792 1.33333 7.99992 1.33333ZM7.99992 3.33333C7.7347 3.33333 7.48035 3.43869 7.29281 3.62622C7.10528 3.81376 6.99992 4.06811 6.99992 4.33333C6.99992 4.59855 7.10528 4.8529 7.29281 5.04044C7.48035 5.22797 7.7347 5.33333 7.99992 5.33333C8.26514 5.33333 8.51949 5.22797 8.70703 5.04044C8.89456 4.8529 8.99992 4.59855 8.99992 4.33333C8.99992 4.06811 8.89456 3.81376 8.70703 3.62622C8.51949 3.43869 8.26514 3.33333 7.99992 3.33333Z"
              fill="black"
            />
          </svg>
          Web3 Games
        </li>
        {/* <li className="options-list-items">
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.97739 6.3125C1.89307 6.31326 1.81005 6.2917 1.73676 6.25C1.68301 6.21978 1.63582 6.17915 1.59795 6.13048C1.56009 6.08181 1.53232 6.02607 1.51625 5.96653C1.50019 5.907 1.49616 5.84486 1.50441 5.78374C1.51265 5.72263 1.533 5.66378 1.56426 5.61062C2.16676 4.605 3.99989 2.25781 7.99989 2.25781C9.73332 2.25781 11.2536 2.71313 12.5164 3.61063C13.5558 4.34719 14.1383 5.18094 14.4158 5.5825C14.451 5.63312 14.4757 5.69026 14.4885 5.75057C14.5013 5.81089 14.5019 5.87314 14.4902 5.93368C14.4786 5.99422 14.4549 6.05182 14.4207 6.10308C14.3864 6.15435 14.3423 6.19824 14.2908 6.23219C14.1856 6.30172 14.0575 6.32755 13.9336 6.30422C13.8097 6.28088 13.6998 6.21021 13.6271 6.10719C13.1249 5.385 11.5936 3.1875 7.99989 3.1875C4.49207 3.1875 2.90614 5.20969 2.38957 6.07688C2.34807 6.14929 2.28798 6.20931 2.21552 6.25073C2.14306 6.29216 2.06085 6.31348 1.97739 6.3125Z"
                            fill="black"
                        />
                        <path
                            d="M10.0153 15.5C9.97537 15.5005 9.93559 15.496 9.89682 15.4866C6.99526 14.7678 5.91182 11.8672 5.86776 11.7466L5.86088 11.72C5.83713 11.6363 5.25495 9.64719 6.1487 8.48219C6.55838 7.95094 7.18151 7.67844 8.00432 7.67844C8.76932 7.67844 9.3212 7.91625 9.70057 8.40844C10.0131 8.81031 10.1381 9.30594 10.259 9.78344C10.5131 10.7778 10.6965 11.3 11.7524 11.3538C12.2162 11.3772 12.5209 11.1059 12.6937 10.875C13.1609 10.2453 13.2421 9.21875 12.8899 8.3125C12.4374 7.14281 10.8315 4.9375 7.99995 4.9375C6.7912 4.9375 5.68057 5.32594 4.78995 6.05594C4.05276 6.66062 3.4687 7.51438 3.18745 8.39188C2.66588 10.0256 3.34995 12.5938 3.35651 12.6172C3.37248 12.677 3.37632 12.7393 3.36781 12.8006C3.3593 12.8619 3.3386 12.9208 3.30694 12.974C3.27529 13.0271 3.23331 13.0734 3.18348 13.1101C3.13365 13.1468 3.07698 13.1731 3.01682 13.1875C2.89528 13.2198 2.76591 13.2032 2.65644 13.1413C2.54698 13.0794 2.46612 12.9771 2.4312 12.8562C2.39995 12.7391 1.66932 10 2.27182 8.11187C2.92807 6.06594 4.95338 4.00344 8.00088 4.00344C9.40932 4.00344 10.7399 4.48219 11.8506 5.38625C12.7106 6.08938 13.4131 7.03375 13.7806 7.97781C14.2478 9.18312 14.1224 10.5359 13.4621 11.4197C13.0221 12.0091 12.3956 12.3172 11.7015 12.2837C9.89339 12.1931 9.56838 10.9309 9.33151 10.0103C9.08776 9.06594 8.93182 8.61063 8.00088 8.61063C7.48963 8.61063 7.13057 8.75125 6.90713 9.0425C6.60245 9.44094 6.5787 10.0637 6.61245 10.5156C6.6346 10.8305 6.68799 11.1423 6.77182 11.4466C6.8462 11.6341 7.81464 14.0091 10.134 14.5838C10.194 14.598 10.2505 14.6239 10.3003 14.6602C10.3501 14.6964 10.3923 14.7422 10.4242 14.7948C10.4562 14.8475 10.4774 14.906 10.4866 14.9669C10.4958 15.0278 10.4928 15.0899 10.4778 15.1497C10.4495 15.2505 10.3889 15.3394 10.3054 15.4026C10.2219 15.4659 10.12 15.5001 10.0153 15.5Z"
                            fill="black"
                        />
                        <path
                            d="M6.29102 15.2856C6.22599 15.2858 6.16159 15.2728 6.10168 15.2475C6.04176 15.2222 5.98755 15.1851 5.94227 15.1384C4.78102 13.9197 4.12415 12.5569 3.87696 10.8528V10.8438C3.73821 9.71563 3.94134 8.11844 4.93665 7.02031C5.67134 6.21 6.70415 5.79813 8.00102 5.79813C9.53477 5.79813 10.7401 6.51125 11.4916 7.85719C12.037 8.835 12.1451 9.80938 12.1479 9.84938C12.1536 9.91105 12.1471 9.97324 12.1288 10.0324C12.1104 10.0916 12.0806 10.1465 12.0409 10.1941C12.0013 10.2417 11.9527 10.281 11.8978 10.3098C11.843 10.3386 11.783 10.3563 11.7213 10.3619C11.5969 10.3754 11.4721 10.3394 11.3739 10.2617C11.2758 10.184 11.2121 10.0708 11.1966 9.94656C11.1143 9.36192 10.9251 8.79742 10.6385 8.28125C10.056 7.25344 9.16977 6.73094 7.99759 6.73094C6.98509 6.73094 6.1929 7.03563 5.6479 7.63719C4.86227 8.50438 4.7104 9.84031 4.81821 10.7241C5.03477 12.2309 5.61384 13.4297 6.63634 14.5009C6.67901 14.5453 6.71233 14.5979 6.7343 14.6554C6.75627 14.7129 6.76645 14.7743 6.76423 14.8358C6.76202 14.8974 6.74745 14.9578 6.7214 15.0136C6.69535 15.0694 6.65834 15.1194 6.61259 15.1606C6.52447 15.2405 6.40995 15.285 6.29102 15.2856Z"
                            fill="black"
                        />
                        <path
                            d="M11.6406 13.9431C10.625 13.9431 9.76157 13.6619 9.07063 13.1028C7.68251 11.9844 7.52688 10.1628 7.52001 10.0859C7.51023 9.95975 7.55098 9.83485 7.63329 9.73871C7.7156 9.64257 7.83273 9.58306 7.95892 9.57328C8.0851 9.5635 8.21 9.60425 8.30614 9.68656C8.40229 9.76887 8.46179 9.886 8.47157 10.0122C8.47501 10.0391 8.61376 11.5291 9.68376 12.3872C10.3169 12.8928 11.1631 13.0934 12.2059 12.9762C12.3306 12.9611 12.4561 12.996 12.5552 13.0731C12.6542 13.1503 12.7186 13.2636 12.7344 13.3881C12.7412 13.4497 12.7357 13.5119 12.7182 13.5713C12.7007 13.6307 12.6716 13.686 12.6325 13.734C12.5934 13.782 12.5451 13.8217 12.4905 13.8509C12.4359 13.88 12.376 13.898 12.3144 13.9038C12.0907 13.9298 11.8658 13.943 11.6406 13.9431ZM12.4431 1.52469C12.0469 1.26688 10.6419 0.5 8.00001 0.5C5.22688 0.5 3.81845 1.34719 3.50688 1.5625C3.48631 1.57513 3.46705 1.58979 3.44938 1.60625C3.44752 1.60805 3.44509 1.60916 3.44251 1.60938C3.39276 1.65281 3.35284 1.70636 3.32541 1.76644C3.29798 1.82653 3.28368 1.89177 3.28345 1.95781C3.2843 2.01972 3.29737 2.08086 3.32189 2.13771C3.34641 2.19457 3.38191 2.24602 3.42636 2.28913C3.4708 2.33224 3.52332 2.36616 3.58089 2.38894C3.63847 2.41172 3.69998 2.42291 3.76188 2.42188C3.86111 2.42181 3.95795 2.39138 4.03938 2.33469C4.05282 2.32469 5.26501 1.43406 8.00095 1.43406C10.7369 1.43406 11.9556 2.32156 11.9688 2.32812C12.052 2.38982 12.153 2.42273 12.2566 2.42188C12.3185 2.42283 12.3801 2.41153 12.4377 2.38864C12.4952 2.36574 12.5477 2.3317 12.5921 2.28847C12.6365 2.24523 12.6719 2.19366 12.6964 2.1367C12.7208 2.07974 12.7337 2.01853 12.7344 1.95656C12.7344 1.86375 12.7067 1.77304 12.6548 1.69609C12.6029 1.61913 12.5292 1.55945 12.4431 1.52469Z"
                            fill="black"
                        />
                    </svg>
                    Claimer Page
                </li> */}
      </ul>
    </div>
  );
}
