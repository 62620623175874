import ReactGA from "react-ga4";

export const contractDetailsGAFunctions = {
  viewOnBlockExplorerEvent: function () {
    ReactGA.event({
      action: "View On Block Explorer",
      category: "Contract Details",
    });
  },
  openMintPageEvent: function () {
    ReactGA.event({
      action: "Open Mint Page",
      category: "Contract Details",
    });
  },
  addWhitelistEvent: function () {
    ReactGA.event({
      action: "Add Whitelist User",
      category: "Contract Details",
    });
  },
  tokensEvent: function () {
    ReactGA.event({
      action: "Tokens Clicked",
      category: "Contract Details",
    });
  },
  overviewEvent: function () {
    ReactGA.event({
      action: "Overview Clicked",
      category: "Contract Details",
    });
  },
  createNFTEvent: function () {
    ReactGA.event({
      action: "Create Button Clicked",
      category: "Contract Details",
    });
  },
  viewNFTEvent: function () {
    ReactGA.event({
      action: "NFT Details Clicked",
      category: "Mint Token",
    });
  },
  mintNFTEvent: function () {
    ReactGA.event({
      action: "Mint NFT Clicked",
      category: "Mint Token",
    });
  },
  shareEvent: function () {
    ReactGA.event({
      action: "Share on Twitter Clicked",
      category: "Mint Token",
    });
  },
};
