export default function GetContractType(contractTypeFromBackend) {
  const contractType = {
    ERC721: "ERC-721",
    ERC721A: "ERC-721A",
    ERC1155: "ERC-1155",
    biconomy: "ERC721A (Gassless)",
    biconomy1155: "ERC1155 (Gassless)",
    ERC721ACollection: "ERC-721A",
    ERC1155Collection: "ERC-1155",
    multiple: "Multiple Editions",
    oneOnone: "1/1 NFT",
    collection: "Collection",
  };
  return contractType[contractTypeFromBackend];
}
