import ReactGA from "react-ga4";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header";
import { ModalOverlayContainer } from "./components/modals/ModalOverlay";
import { AuthContextProvider } from "./context/AuthContext";
import { MintContextProvider } from "./context/MintPageContext";
import DeployContract from "./pages/DeployContract";
import Claimer from "./pages/DeployContract/Claimer";
import ContractDetails from "./pages/DeployContract/ContractDetails";
import DeployNewContract from "./pages/DeployContract/DeployNewContract";
import MintNFT from "./pages/DeployContract/MintNFT";
import Home from "./pages/Home";
import Mint from "./pages/Mint";

export default function App() {
  ReactGA.initialize("G-DV9CENVS5E");
  ReactGA.send("Home");
  const location = useLocation();
  const { pathname } = location;
  const currentPath = pathname.split("/")[1];
  let stringValue = "https://lync.world/";

  return (
    <>
      {currentPath == "mint" ? null : (
        <AuthContextProvider>
          <Header />
        </AuthContextProvider>
      )}
      <Routes>
        <Route
          path="/mint-tokens"
          exact
          element={
            <AuthContextProvider>
              <MintNFT />
            </AuthContextProvider>
          }
        />
        <Route
          path="/"
          exact
          element={
            <AuthContextProvider>
              <Home />
            </AuthContextProvider>
          }
        />
        <Route
          path="/your-contracts"
          exact
          element={
            <AuthContextProvider>
              <DeployContract />
            </AuthContextProvider>
          }
        />
        <Route
          path="/contract/:contractId"
          element={
            <AuthContextProvider>
              <ContractDetails />
            </AuthContextProvider>
          }
        />
        <Route
          path="/create-contract"
          element={
            <AuthContextProvider>
              <DeployNewContract />
            </AuthContextProvider>
          }
        />
        <Route
          path="/nft/:contractAddress"
          element={
            <AuthContextProvider>
              <Claimer />
            </AuthContextProvider>
          }
        />
        <Route
          path="/mint/:chainName/:contractAddress"
          element={
            <MintContextProvider>
              <Mint />
            </MintContextProvider>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ModalOverlayContainer />
    </>
  );
}
