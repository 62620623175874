const getContracts = "get-contracts";
const getWhiteListUser = "white-list-user/get?contractAddress=";
const addWhiteListUser = "white-list-user/insert";
const compileContract = "compile-contract";
const verifyContract = "verify-contract";
const getClamierDetails = "get-clamier-details";
const updatWhiteListUser = "white-list-user/update";
const getBiconomyContracts = "get-biconomy-contracts";
export const APIRoutes = {
  getContracts,
  getWhiteListUser,
  addWhiteListUser,
  compileContract,
  verifyContract,
  getClamierDetails,
  updatWhiteListUser,
  getBiconomyContracts,
};
