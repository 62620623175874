import ReactGA from "react-ga4";

export const createAsGAFunctions = {
  oneEvent: function () {
    ReactGA.event({
      action: "1/1 NFT",
      category: "Create As",
    });
  },
  multipleEditionEvent: function () {
    ReactGA.event({
      action: "Multiple Edition",
      category: "Create As",
    });
  },
  collectionEvent: function () {
    ReactGA.event({
      action: "Collection",
      category: "Create As",
    });
  },
  launchGameEvent: function () {
    ReactGA.event({
      action: "Launch Game",
      category: "Create As",
    });
  },
  getStartedEvent: function () {
    ReactGA.event({
      action: "Get Started",
      category: "Create As",
    });
  },
  createEvent: function () {
    ReactGA.event({
      action: "Create",
      category: "Create As",
    });
  },
  knowMoreEvent: function () {
    ReactGA.event({
      action: "Know More",
      category: "Create As",
    });
  },
};
