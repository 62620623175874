import { appImages } from "../constants/images";

const constantsValues = {
  "0x13881": {
    chainID: "0x13881",
    forwarder: "0x69015912AA33720b842dCD6aC059Ed623F28d9f7",
    blockExplorer: "https://mumbai.polygonscan.com/address/",
    ChainName: "Mumbai ",
    slug: "Mumbai",
    testnet: "true",
    openSeaCollection: "https://testnets.opensea.io/assets?search[query]=",
    openSeaNFTDetails: "https://testnets.opensea.io/assets/mumbai/",
    raribleNFTDetails: "https://testnet.rarible.com/token/polygon/",
    raribleCollection: "https://testnet.rarible.com/collection/polygon/",
    rpcURL: "https://rpc-mumbai.maticvigil.com",
    logo: appImages.mumbaiLogo,
  },
  "0x5a2": {
    chainID: "0x5a2",
    blockExplorer: "https://testnet-zkevm.polygonscan.com/address/",
    forwarder: "0x5671313303DFbEBa85a4e8b165ff4cC7FADD24AD",
    ChainName: "Polygon ZkEVM",
    testnet: "true",
    slug: "zkEVMTestnet",
    rpcURL: "https://rpc.public.zkevm-test.net",
    logo: appImages.mumbaiLogo,
  },
  // "0x89": {
  //   chainID: "0x89",
  //   forwarder: "0xf0511f123164602042ab2bCF02111fA5D3Fe97CD",
  //   blockExplorer: "https://polygonscan.com/address/",
  //   ChainName: "Polygon mainnet",
  //   testnet: "false",
  //   slug: "polygon",
  //   openSeaCollection: "https://opensea.io/assets?search[query]=",
  //   openSeaNFTDetails: "https://opensea.io/assets/matic/",
  //   raribleCollection: "https://rarible.com/collection/polygon/",
  //   rpcURL: "https://polygon.llamarpc.com",
  //   logo: appImages.mumbaiLogo,
  // },
  "0x51": {
    chainID: "0x51",
    blockExplorer: "https://blockscout.com/shibuya/address/",
    ChainName: "Astar Shibuya",
    testnet: "true",
    slug: "Shibuya",
    rpcURL: "https://evm.shibuya.astar.network",
    logo: appImages.astarLogo,
  },
  //"0x5fd8b025973e3": {
  //  chainID: "0x5fd8b025973e3",
  //  blockExplorer:
  //    "https://lyncworld-1686148365251555-1.sp1.sagaexplorer.io/address/",
  //  ChainName: "lyncworld",
  //  testnet: "true",
  //  slug: "lync",
  //  rpcURL: "https://lyncworld-1686148365251555-1.jsonrpc.sp1.sagarpc.io",
  //  logo: appImages.lyncblack,
  //},
  // "0x1": {
  //   chainID: "0x1",
  //   forwarder: "0xd43Ed82B8d822cB1a04ce84b5332e0B2DDf97760",
  //   blockExplorer: "https://etherscan.io/address/",
  //   ChainName: "Ethereum mainnet",
  //   testnet: "false",
  //   slug: "ethereum",
  //   openSeaCollection: "https://opensea.io/assets?search[query]=",
  //   openSeaNFTDetails: "https://opensea.io/assets/ethereum/",
  //   raribleCollection: "https://rarible.com/collection/polygon/",
  //   rpcURL: "https://mainnet.infura.io/v3/",
  //   logo: appImages.ethMainnetLogo,
  // },
  "0x5": {
    chainID: "0x5",
    blockExplorer: "https://goerli.etherscan.io/address/",
    ChainName: "Goerli",
    slug: "Goerli",
    forwarder: "0xE041608922d06a4F26C0d4c27d8bCD01daf1f792",
    testnet: "true",
    openSeaCollection: "https://testnets.opensea.io/assets?search[query]=",
    openSeaNFTDetails: "https://testnets.opensea.io/assets/goerli/",
    raribleCollection: "https://testnet.rarible.com/collection/",
    raribleNFTDetails: "https://testnet.rarible.com/token/",
    rpcURL: "https://goerli.blockpi.network/v1/rpc/public",
    logo: appImages.ethGoerliLogo,
  },
  // "0x250": {
  //   chainID: "0x250",
  //   blockExplorer: "https://astar.network/address/",
  //   ChainName: "Astar Mainnet",
  //   testnet: "false",
  //   slug: "astar",
  //   openSeaCollection: "https://opensea.io/assets?search[query]=",
  //   raribleCollection: "https://rarible.com/collection/polygon/",
  //   rpcURL: "https://evm.astar.network",
  //   logo: appImages.astarLogo,
  // },
  // "0x144": {
  //   chainID: "0x144",
  //   blockExplorer: "https://explorer.zksync.io/address/",
  //   ChainName: "zkSync Mainnet",
  //   testnet: "false",
  //   slug: "zkSync",
  //   openSeaCollection: "https://opensea.io/assets?search[query]=",
  //   raribleCollection: "https://rarible.com/collection/polygon/",
  //   rpcURL: "https://mainnet.era.zksync.io",
  //   logo: appImages.mantleLogo,
  // },
  "0x118": {
    chainID: "0x118",
    blockExplorer: "https://goerli.explorer.zksync.io/address/",
    ChainName: "zkSync",
    testnet: "true",
    slug: "zkSyncTestnet",
    rpcURL: "https://testnet.era.zksync.dev",
    logo: appImages.zksyncLogo,
  },
  // "0x1388": {
  //   chainID: "0x1388",
  //   blockExplorer: "https://explorer.testnet.mantle.xyz", //todo
  //   ChainName: "Mantle Mainnet",
  //   testnet: "false",
  //   slug: "Mantle",
  //   openSeaCollection: "https://opensea.io/assets?search[query]=",
  //   raribleCollection: "https://rarible.com/collection/polygon/",
  //   rpcURL: "https://rpc.mantle.xyz",
  //   logo: appImages.mantleLogo,
  // },
  "0x1389": {
    chainID: "0x1389",
    blockExplorer: "https://explorer.testnet.mantle.xyz/address/",
    ChainName: "Mantle",
    testnet: "true",
    slug: "MantleTestnet",
    rpcURL: "https://rpc.testnet.mantle.xyz",
    logo: appImages.mantleL,
  },
  // "0xA4B1": {
  //   chainID: "0xA4B1",
  //   blockExplorer: "https://explorer.arbitrum.io/address/",
  //   ChainName: "Arbitrum one mainnet",
  //   testnet: "false",
  //   slug: "arbitrum",
  //   openSeaCollection: "https://opensea.io/assets?search[query]=",
  //   openSeaNFTDetails: "https://opensea.io/assets/arbitrum-nova/",
  //   raribleCollection: "https://rarible.com/collection/polygon/",
  //   rpcURL: "https://arb1.arbitrum.io/rpc",
  //   logo: appImages.mantleLogo,
  // },
  "0x66eed": {
    chainID: "0x66eed",
    blockExplorer: "https://goerli.arbiscan.io/address/",
    ChainName: "Arbitrum",
    slug: "ArbitrumGoerli",
    testnet: "true",
    openSeaCollection: "https://testnets.opensea.io/assets?search[query]=",
    openSeaNFTDetails: "https://testnets.opensea.io/assets/arbitrum-goerli/",
    rpcURL: "https://goerli-rollup.arbitrum.io/rpc",
    logo: appImages.arbitrumLogo,
  },
  "0x4cb2f": {
    chainID: "0x4cb2f",
    blockExplorer: "https://calibration.filfox.info/en/address/",
    ChainName: "FEVM",
    testnet: "true",
    slug: "FevmTestnet",
    rpcURL: "https://filecoin-calibration.chainup.net/rpc/v1",
    logo: appImages.filecoinLogo,
  },
  "0x128": {
    chainID: "0x128",
    blockExplorer: "https://hashscan.io/testnet/account/",
    ChainName: "Hedera",
    testnet: "true",
    slug: "HederaTestnet",
    rpcURL: "https://testnet.hashio.io/api",
    logo: appImages.hederaLogo,
  },
  "0x15eb": {
    chainID: "0x15eb",
    blockExplorer: "https://opbnb-testnet.bscscan.com/address/",
    ChainName: "opBNB Testnet",
    testnet: "true",
    slug: "opBNBTestnet",
    rpcURL: "https://opbnb-testnet-rpc.bnbchain.org",
    logo: appImages.bnb,
  },
  "0x61": {
    chainID: "0x61",
    blockExplorer: "https://testnet.bscscan.com/address/",
    ChainName: "BNB Testnet",
    testnet: "true",
    slug: "BNBTestnet",
    rpcURL: "https://bsc-testnet.publicnode.com",
    logo: appImages.bnb,
  },
  "0x45b": {
    chainID: "0x45b",
    blockExplorer: "https://scan.test.btcs.network/address/",
    ChainName: "Core Dao Testnet",
    testnet: "true",
    slug: "CoreDaoTestnet",
    rpcURL: "https://rpc.test.btcs.network/",
    logo: appImages.coreDao,
  },
  "0x138d5": {
    chainID: "0x138d5",
    blockExplorer: "https://artio.beratrail.io/address/",
    ChainName: "Bera Testnet",
    testnet: "true",
    slug: "BeraTestnet",
    rpcURL: "https://rpc.ankr.com/berachain_testnet/",
    logo: appImages.bera,
  },
  // "0x42": {
  //   chainID: "0x42",
  //   blockExplorer: "https://www.oklink.com/oktc/address/",
  //   ChainName: "OKC Mainnet",
  //   testnet: "false",
  //   slug: "okc",
  //   openSeaCollection: "https://opensea.io/assets?search[query]=",
  //   raribleCollection: "https://rarible.com/collection/polygon/",
  //   rpcURL: "https://exchainrpc.okex.org",
  //    logo: appImages.mantleLogo,
  // },
  // "0xA86A": {
  //   chainID: "0xA86A",
  //   blockExplorer: "https://subnets.avax.network/address/",
  //   ChainName: "Avalanche mainnet",
  //   testnet: "false",
  //   slug: "avalanche",
  //   openSeaCollection: "https://opensea.io/assets?search[query]=",
  //   openSeaNFTDetails: "https://opensea.io/assets/avalanche/",
  //   raribleCollection: "https://rarible.com/collection/polygon/",
  //   rpcURL: "https://api.avax.network/ext/bc/C/rpc",
  //   logo: appImages.mantleLogo,
  // },
  // "0xA869": {
  //   chainID: "0xA869",
  //   blockExplorer: "https://subnets-test.avax.network/address/",
  //   ChainName: "Avalanche fuji",
  //   testnet: "true",
  //   slug: "fuji",
  //   openSeaCollection: "https://opensea.io/assets?search[query]=",
  //   openSeaNFTDetails: "https://opensea.io/assets/matic/", //todo
  //   raribleCollection: "https://rarible.com/collection/polygon/",
  //   rpcURL: "https://api.avax-test.network/ext/bc/C/rpc",
  // logo: appImages.mantleLogo,
  // },
  // "0x41": {
  //   chainID: "0x41",
  //   blockExplorer: "https://www.oklink.com/oktc-test/address/",
  //   ChainName: "OKC",
  //   testnet: "true",
  //   slug: "okctestnet",
  //   openSeaCollection: "https://opensea.io/assets?search[query]=",
  //   raribleCollection: "https://rarible.com/collection/polygon/",
  //   rpcURL: "https://exchaintestrpc.okex.org",
  // logo: appImages.mantleLogo,
  // },
};

const defautlChainId = "0x13881";
export { defautlChainId };
export default constantsValues;
