import ReactGA from "react-ga4";

export const deployGAFunctions = {
  deployOnPolygonEvent: function () {
    ReactGA.event({
      action: "Deploy On Polygon",
      category: "Deploy",
    });
  },
  newContractEvent: function () {
    ReactGA.event({
      action: "New Contract",
      category: "Deploy",
    });
  },
};
