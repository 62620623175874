import { useState } from "react";

import { CreateOptions } from "./CreateOptions";
import { CreateNFT } from "./CreateNFT";
import { MultipleEditions } from "./MultipleEditions";
import { LaunchCollections } from "./LaunchCollections";
import { LaunchGame } from "./LaunchGame";

export default function Home() {
    const [createOptionID, setCreateOptionID] = useState(0);

    return (
        <>
            <div className="home">
                <div className="container">
                    <CreateOptions
                        createOptionID={createOptionID}
                        setCreateOptionID={setCreateOptionID}
                    />
                    <div className="content-display-container">
                        {createOptionID === 0 ? <CreateNFT /> : null}
                        {createOptionID === 1 ? <MultipleEditions /> : null}
                        {createOptionID === 2 ? <LaunchCollections /> : null}
                        {createOptionID === 3 ? <LaunchGame /> : null}
                    </div>
                </div>
            </div>
        </>
    );
}
